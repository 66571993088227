import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'
import HOPRUserVPNExplorer from "../assets/gnosisvpn/HOPR_User-VPN_Explorer.json";

const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

function Section3() {
    return (
        <SSection
            id={'Section3'}
            center
            yellow
            fullHeight
        >
            <Typography type="h2">
                What about centralized VPN<span style={{ textTransform: 'lowercase' }}>s</span>?
            </Typography>
            <Brick
                className='mbt80'
                text="With centralized VPNs you entrust your metadata to their server operators that they shield from websites. VPNs replace trust in one server with trust in another."
                reverse
                animationId='HOPRUserVPNExplorer'
                animationData={HOPRUserVPNExplorer}
            />
            <Brick
                className='mbt80'
                // title="NFT FRONTRUN"
                text={<>As a web3 user on a centralized VPN, your anonymity set is the set of all users using:
                    <ul style={{ marginBottom: '0' }}>
                        <li>The same RPC provider</li>
                        <li>Using the same VPN server</li>
                        <li>At the same time</li>
                    </ul>
                    This is almost certainly 1. So your identity can still be linked to activities on the web2.0 and web3.
                </>}
                image="/gnosisvpn/HOPR_Privacy_loves_company.svg"
            />
        </SSection>
    );
}

export default Section3;
