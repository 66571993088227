import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'
import Button from '../components/Button/index.jsx'

const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

const Image = styled.img`
  height: auto;
  max-width: 100%;
  &.mobileOnly {
    margin-bottom: 16px;
    @media (min-width: 700px) {
      display: none;
    }
  }
`

function Section7() {
    return (
        <SSection
            id={'Section7'}
            center
            fullHeight
        >
            <Typography type="h2">
                Unlinked HTTP
            </Typography>

            <Typography center>
                As a first step towards GnosisVPN, you can now use unlinked HTTP (uHTTP) which routes HTTP requests and responses through the HOPR network - right from your browser without installing any additional apps or services.
            </Typography>
            <br/>
            <Image src="/gnosisvpn/Architecture_Graphic_WebApp_uhttp.svg"/>
            <br/>
            <Typography center>
                WARNING(*): In this first version, HOPR Association's exit nodes will see all plaintext HTTP requests and responses, so do not use them to route any confidential information. Exit nodes should be operated on-premises of application providers. Please contact us if you would like to operate a uHTTP exit node to offer anonymous routing for your users.
            </Typography>

            <Button
                hopr
                href='mailto:uHTTP@hoprnet.org'
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
            >
               Contact us
            </Button>


        </SSection>
    );
}

export default Section7;
