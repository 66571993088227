import React from "react";
import styled from "@emotion/styled";

import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Button from '../components/Button/index.jsx'

import PoweredByHopr from '../components/PoweredByHOPR/index.jsx'

const SSection = styled(Section)`
  padding-bottom: 40px;
  padding-top: 40px;
  align-items: flex-start;
  background-image: url('/images/RPCh-BG.jpg');
  background-position: top;
  background-size: cover;
`

function Section4() {
    return (
        <SSection
            center
            blue
        >
            <Typography
                type="h6"
                className="mb32"
                style={{color: '#000050'}}
            >
                Explore web2.0-to-web3 user data fingerprinting
            </Typography>
            <Button
                hopr
                href='https://mytokentracker.xyz/'
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
            >
                Track me
            </Button>
        </SSection>
    );
}

export default Section4;
