import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'


const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

const Image = styled.img`
  height: auto;
  max-width: 100%;
  &.mobileOnly {
    margin-bottom: 16px;
    @media (min-width: 700px) {
      display: none;
    }
  }
`

function Section5() {
    return (
        <SSection
            id={'Section5'}
            center
            fullHeight
        >
            <Typography type="h2">
                Let’s fix VPNs for good!
            </Typography>

            <Typography center>
                GnosisVPN routes data through the underlying HOPR network. The traffic is sent through changing routes via multiple  <a
                    href="https://hoprnet.org/node"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    HOPR nodes
                </a> which makes it impossible for the recipient or third parties to reliably figure out where the data originated. In addition, data packets are mixed up and transformed to prevent linkability of incoming and outgoing packets. The HOPR network is decentralized and   <a
                    href="https://network.hoprnet.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    run by hundreds of individual node runners
                </a> all across the globe.
            </Typography>
            <Image src="/gnosisvpn/Architecture_Graphic_GnosisVPN_end-to-endTLS.svg"/>
        </SSection>
    );
}

export default Section5;
