import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'


const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

function Section6() {
    return (
        <SSection
            id={'Section6'}
            center
            yellow
            fullHeight
        >
            <Typography type="h2">
                Why is this hard?
            </Typography>
            <Typography center>
                Users demand a solution to the <a
                    href="https://eprint.iacr.org/2017/954.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    anonymity trilemma
                </a> along with a user experience no different from today’s web2.0 applications. That means:
                <ul style={{marginTop: '1rem'}}>
                    <li>Strong anonymity</li>
                    <li>Low latency</li>
                    <li>Low bandwidth overhead</li>
                    <li>Protocol agnostic integration</li>
                    <li>Seamless UX</li>
                </ul>

                Gnosis VPN will be a maximally anonymous VPN for every internet user. It will securely and privately tunnel connections from a user device through the HOPR network to an exit node which will request standard (web) resources and respond in the same fashion. Gnosis VPN will support typical web traffic via HTTP(S) as well as other applications such as streaming services which use UDP or related transport protocols.

            </Typography>
        </SSection>
    );
}

export default Section6;
