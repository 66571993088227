import React, { useState } from "react";
import './App.css';
import typingBotAnimation from './assets/typing-bot-animation.json';

import Layout from './components/Layout/index.jsx'


import Section1 from './sections/Section1.jsx'
import Section2 from './sections/Section2.jsx'
import Section4 from './sections/Section3.jsx'
import Section3 from './sections/Section4.jsx'
import Section5 from './sections/Section5.jsx'
import Section6 from './sections/Section6.jsx'
import Section7 from './sections/Section7.jsx'
import Section8 from './sections/Section8.jsx'
import Section9 from './sections/Section9.jsx'
import EncourageSection from "./components/EncourageSection/index.js";

function App() {
  const [showSetup, setShowSetup] = useState(false);


  function showSetupWithScroll () {
      setShowSetup(true);
      const section = document.querySelector( '#setup-section' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }

  return (
    <div className="App">
      <Layout
          setShowSetup={showSetupWithScroll}
      >
          <Section1
              setShowSetup={showSetupWithScroll}
          />
          <Section2/>
          <Section4/>
          <Section3/>
          <Section5/>
          <Section6/>
          <Section7/>
          <Section8/>
          <Section9/>
      </Layout>
    </div>
  );
}

export default App;
