import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";

import Section from "../components/Section/index.jsx";
import Typography from "../components/Typography/index.jsx";
import Button from "../components/Button/index.jsx";

import { ReactComponent as HoprBall } from "../assets/hopr-ball.svg";
import derpAnimation from "../assets/derp-animation.json";
import lottie from "lottie-web";

const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 0;
`;

const ImageContainer = styled.div`
  max-width: 780px;
  width: 100%;
  position: relative;
`;

const Animation = styled.div`
  max-width: 400px;
  max-height: 400px;
  width: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Subtext = styled(Typography)`
  max-width: 640px;
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
`;

const Disclaimer = styled(Subtext)`
  margin-top: 32px;
  font-size: 14px;
  text-align: center;
`;

function Section1(props) {
  const animationLoaded = useRef(false);

  useEffect(() => {
    // check to prevent double animation load on page remount
    if (!animationLoaded.current) {
      lottie.loadAnimation({
        container: document.querySelector(`#derp-animation`),
        animationData: derpAnimation,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    animationLoaded.current = true;
  }, []);

  return (
    <SSection
      id={"Section1"}
      gradient
      center
      fullHeight
    >
      <ImageContainer>
        <HoprBall />
        {/*<Image*/}
        {/*    alt="Hopr logo"*/}
        {/*    src={'/images/derp_top-header.png'}*/}
        {/*/>*/}
        <Animation id="derp-animation" />
      </ImageContainer>

      <Typography type="h2">
        Gnosis VPN
      </Typography>

      <Subtext center>
        Towards a web3-era VPN without central points of data availability, failure or control
      </Subtext>
      <br/><br/>
      <Subtext center>
      GnosisVPN is built on top of the <a
          href="https://hoprnet.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          HOPR network
        </a> for <a
          href="https://www.gnosis.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gnosis DAO
        </a> and made possible via <a
          href="https://forum.gnosis.io/t/gip-98-should-gnosisdao-invest-in-hopr-to-kickstart-development-of-gnosisvpn/8348"
          target="_blank"
          rel="noopener noreferrer"
        >
          GIP-98
        </a>.
      </Subtext>
    </SSection>
  );
}

export default Section1;
